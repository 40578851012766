import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logo } from "./images/index";
import "./styles/MainNavbarStyle.css";
import Footer from "./Component/LayoutComp/Footer";
import GoToTop from "./Component/LayoutComp/GoToTop";
import "react-creative-cursor/dist/styles.css";
import { useSelector } from "react-redux";
import { LogoutUser } from "./redux/actions/actions";
import { useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";


const MainNavbar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Get the navigate function

  const phoneNumber = "+91-7972156073";


  const { isLoggedIn, userType } = useSelector(state => state.data);
  console.log("🚀 ~ MainNavbar ~ isLoggedIn:", isLoggedIn)

  const handleLogout = () => {
    dispatch(LogoutUser());
    navigate("/login")
  };

  // Add active class
  const [currentPath, setCurrentPath] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  const [menu, setMenu] = React.useState(true);

  // Function to toggle the navbar menu
  const toggleNavbar = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    elementId.classList.add("is-sticky");

  }, []);

  // Function to close the navbar when a link is clicked
  const closeNavbar = () => {
    if (!menu) {
      toggleNavbar();
    }
  };

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const textColorStyle = isScrolled ? { color: "white" } : { color: "black" };

  const logoStyle = {
    height: "60px",
    width: "auto",
  };

  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const pathsToShowFooter = [
    "/",
    "/about",
    "/service",
    "/portfolio",
    "/contact",
  ];
  const shouldShowFooter = pathsToShowFooter.includes(location.pathname);

  return (
    <>
      <div id="navbar" className="navbar-area ">
        <nav className="navbar navbar-expand-md navbar-light ">
          <div className="container p-0  ">
            <div className="oval">
              <Link to="/" className="navbar-brand  p-0 ms-2">
                <img src={logo} style={logoStyle} alt="logo" />
              </Link>
            </div>

            {/* Toggle navigation */}
            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>
            <div
              className={classOne}
              id="navbarSupportedContent"
              style={textColorStyle}
            >
              <ul className="navbar-nav" onClick={closeNavbar}>
                <li
                  className="nav-item"
                  data-cursor-text=" "
                  data-cursor-size="5px"
                >
                  <Link
                    to="/"
                    className={`nav-link ${currentPath === "/" ? "active" : ""
                      }`}
                  >
                    Home
                  </Link>
                </li>

                {


                  isLoggedIn == false ? (


                    <>

                      <li
                        className="nav-item"
                        data-cursor-text=" "
                        data-cursor-size="5px"
                      >
                        <Link
                          to="/about"
                          className={`nav-link ${currentPath === "/about" ? "active" : ""
                            }`}
                        >
                          About us
                        </Link>
                      </li>

                      <li
                        className="nav-item"
                        data-cursor-text=" "
                        data-cursor-size="5px"
                      >
                        <Link
                          to="/portfolio"
                          className={`nav-link ${currentPath === "/portfolio" ? "active" : ""
                            }`}
                        >
                          Portfolio
                        </Link>
                      </li>

                      <li
                        className="nav-item"
                        data-cursor-text=" "
                        data-cursor-size="5px"
                      >
                        <Link
                          to="/contact"
                          className={`nav-link ${currentPath === "/contact" ? "active" : ""
                            }`}
                        >
                          Contact Us
                        </Link>
                      </li>

                    </>
                  ) :
                    (isLoggedIn == true && userType == "USER" ?
                      (
                        <>

                          <li
                            className="nav-item"
                            data-cursor-text=""
                            data-cursor-size="5px"
                          >
                            <Link to="dashboard" className={`nav-link ${currentPath === "/" ? "active" : ""
                              }`}>Dashboard</Link>
                          </li>

                          <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >
                            <Link to="profile" className={`nav-link ${currentPath === "/profile" ? "active" : ""
                              }`}>My Profile</Link>
                          </li>

                          <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >
                            <Link to="photos" className={`nav-link ${currentPath === "/contact" ? "active" : ""
                              }`}>My Photo</Link>
                          </li>

                          <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >
                            <Link to="partnerpref" className={`nav-link ${currentPath === "/contact" ? "active" : ""
                              }`}>Partner Preferences</Link>
                          </li>

                          <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >

                            <Link to="search2" className={`nav-link ${currentPath === "/contact" ? "active" : ""
                              }`}>Search</Link>

                          </li>

                          {/* <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                            onClick={() => handleLogout()}
                          >

                            <Link to="" className={`nav-link ${currentPath === "/contact" ? "active" : ""
                              }`}>logout</Link>

                          </li> */}

                        </>
                      ) : (isLoggedIn == true && userType == "ADMIN" ? (
                        <>
                          <li
                            className="nav-item"
                            data-cursor-text=""
                            data-cursor-size="5px"
                          >
                            <Link to="dashboard" className={`nav-link ${currentPath === "/" ? "active" : ""
                              }`}>Dashboard</Link>
                          </li>

                          <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >
                            <Link to="alluser" className={`nav-link ${currentPath === "/profile" ? "active" : ""
                              }`}>User Details</Link>
                          </li>

                          <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >
                            <Link to="generatekey" className={`nav-link ${currentPath === "/contact" ? "active" : ""
                              }`}>Generate Keys</Link>
                          </li>

                          {/* <li
                            className="nav-item"
                            data-cursor-text=" "
                            data-cursor-size="5px"
                          >
                            <Link to="partnerpref" className={`nav-link ${currentPath === "/contact" ? "active" : ""
                              }`}>Admin Page4</Link>
                          </li> */}
                        </>
                      ) : (
                        <>

                        </>
                      )

                      ))


                }

              </ul>

              <div className="others-options">



                {isLoggedIn != true ? <button className="btn-cssbuttons mx-2">
                  <Link
                    to="/login"
                    className={`nav-link ${currentPath === "/contact" ? "active" : ""
                      }`}
                  >

                    <span>
                      <i
                        color="red"
                        className="fa-solid fa-right-to-bracket"
                      ></i>
                    </span>
                    Login
                  </Link>
                </button> :


                  <button className="btn-cssbuttons mx-2"  onClick={() => handleLogout()} >
                    <div  >
                    <Link
                    
                      to="/"
                      className={`nav-link ${currentPath === "/contact" ? "active" : ""
                        }`}
                        
                    >

                      <span>
                        <i
                          color="red"
                          className="fa-solid fa-right-to-bracket"
                         
                        ></i>
                      </span>
                      Log Out
                    </Link>
                    </div>
                  </button>


                  // <li
                  //   className="nav-item"
                  //   data-cursor-text=" "
                  //   data-cursor-size="5px"
                  //   onClick={() => handleLogout()}
                  // >

                  //   <Link to="" className={`nav-link logout-button-btn px-4 py-1 rounded-pill ${currentPath === "/contact" ? "active" : ""
                  //     }`}>logout</Link>
                  // </li>

                }
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="outlet-class">
        <Toaster />
        <Outlet />
      </div>

      <GoToTop />
      <div className="d-none d-lg-block">{/* <Cursor isGelly={true} /> */}</div>

      <Footer />
    </>
  );
};
export default MainNavbar;