import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const commonRequest = async (methods, endPoint, body, header) => {
 console.log("🚀 ~ commonRequest ~ header:", header)
 
    let config = {
        method: methods,
        url: `${API_URL}/${endPoint}`,
        headers: header ? header : {
            "Content-Type": "application/x-www-form-urlencoded" ,
            "Accept":'*'      
        },
        data: body
    }

    // axios instance
    return axios(config).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export default commonRequest;
