import commonRequest from "../../../CallApis/ApiCall"



///GET ALL USER || METHOD=POST
export const GET_ACTIVATION_KEY_DETAILS_API = async (data) => {
    let endPoint = "activationkeydetails"
    return await commonRequest('POST', endPoint, data)

}


///GET ALL USER || METHOD=POST
export const GENERATE_ACTIVATION_KEY_API = async (data) => {
    let endPoint = "activationkeydetails"
    return await commonRequest('POST', endPoint, data)

}
///GET ALL USER || METHOD=POST
export const GET_ALL_VENDOR_API = async (data) => {
    let endPoint = "admin_panel"
    return await commonRequest('POST', endPoint, data)

}
///GET ALL USER || METHOD=POST
export const DELETE_ACTIVATION_KEY_API = async (data) => {
    let endPoint = "activationkeydetails"
    return await commonRequest('POST', endPoint, data)

}
