import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Link } from "react-router-dom";
import TableLoader from "../../../Loader/TableLoader";
import { useSelector } from "react-redux";
import {
  DELETE_ACTIVATION_KEY_API,
  GENERATE_ACTIVATION_KEY_API,
  GET_ACTIVATION_KEY_DETAILS_API,
  GET_ALL_VENDOR_API,
} from "../../../Services/APIs/ADMIN_APIs/Generatekey";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import SearchableDropdown from "./SearchableDropdown";

export default function GenerateKey() {
  const { userId } = useSelector((state) => state.data);

  const [loadingTable, setLoadingTable] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [keyDetails, setKeyDetails] = useState([]);

  const [allVendor, setAllVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [keyCount, setKeyCount] = useState("");

  const GenerateKey = async () => {
    console.log("🙋🏻‍♂️ ~ GenerateKey ~ selectedVendor:", selectedVendor);
    if (!selectedVendor?.id) {
      toast.error(`Please Select a Vendor!`);
      return;
    }
    if (!keyCount) {
      toast.error(`Please Insert Number of Keys!`);
      return;
    }
    try {
      const params = new URLSearchParams();
      params.append("dbparam", "generatekeys");
      params.append("pr_userid", userId);
      params.append("numofkeys", keyCount);
      params.append("useridforkeys", selectedVendor?.id);

      let response = await GENERATE_ACTIVATION_KEY_API(params);

      let data = response?.data;
      console.log("🚀 ~ getAllVendors  ~ data:", data);

      if (data?.statusCode == 200) {
        toast.success(`${keyCount} Activation Key has been generated!`);
        setKeyCount("");
        getAllKeysDeatils();
      } else {
        toast.error(`Failed to generate Key!`);
      }
    } catch (error) { }
  };

  const getAllVendors = async () => {
    try {
      const params = new URLSearchParams();
      params.append("dbparam", "getallvendor");
      params.append("pr_userid", userId);

      let response = await GET_ALL_VENDOR_API(params);

      let data = response?.data;
      console.log("🚀 ~ getAllVendors  ~ data:", data);

      if (data?.statusCode == 200) {
        setAllVendor(data?.data);
        setSelectedVendor(data?.data[0] || "")
      } else {
      }
    } catch (error) { }
  };

  const getAllKeysDeatils = async () => {
    try {
      const params = new URLSearchParams();
      params.append("dbparam", "getallkeysforadmin");
      params.append("pr_userid", userId);

      let response = await GET_ACTIVATION_KEY_DETAILS_API(params);

      let data = response?.data;
      console.log("🚀 ~ getactivation key  rProfileDetails ~ data:", data);

      if (data?.statusCode == 200) {
        setFilteredData(data?.data);
        // setFilteredData([])
        setKeyDetails(data?.data);
      } else {
      }
    } catch (error) {
    } finally {
      setLoadingTable(false);
    }
  };
  useEffect(() => {
    getAllKeysDeatils();
    getAllVendors();
  }, [userId]);

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    toast.success(`${text} Copied Successfully`);
  };

  // FOR DELETING EPIN
  const confirmButton = (actvKey) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You will not be able to recover this Activation Key (${actvKey})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtoncolor: "",
      cancelButtonText: "No, keep it",
      customClass: {
        popup: "custom-swal-popup",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = new URLSearchParams();
        params.append("dbparam", "deletesinglekey");
        params.append("pr_userid", userId);
        params.append("pr_act_keys", actvKey);

        try {
          const response = await DELETE_ACTIVATION_KEY_API(params);
          if (response.data.statusCode == 200) {
            Swal.fire({
              title: "Deleted!",
              text: ` Activation Key (${actvKey}) has been Deleted`,
              icon: "success",
              customClass: {
                popup: "custom-swal-popup",
              },
            });

            getAllKeysDeatils();
            // setEpinDetails(response.data.data);
            // setFilteredPackageAmt(response.data.data);
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
              customClass: {
                popup: "custom-swal-popup",
              },
            });
          }
        } catch (error) {
          // console.log(error)
        }
      }
    });
  };

  // PAGINATION

  const [currentpage, setCurrentpage] = useState(1);
  const recordperpage = 10;
  const lastIndex = currentpage * recordperpage;
  const firstIndex = lastIndex - recordperpage;
  let records = filteredData;

  records.sort((a, b) => {
    // Check the length of pr_usedby for both items
    const aLength = (a.pr_usedby || "").length;
    const bLength = (b.pr_usedby || "").length;

    // Sort in ascending order of length (shorter lengths at the top)
    return aLength - bLength;
  });
  //// ARRAY OBJECT
  records = records.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordperpage);
  const Number = [...Array(npage + 1).keys()].slice(1);

  function prepage() {
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function Nextpage() {
    if (currentpage !== npage) {
      setCurrentpage(currentpage + 1);
    }
  }
  function ChangeCpage(id) {
    setCurrentpage(id);
  }

  // handleChangeb functions 

  const handleChange = (event, newValue) => {
    setSelectedVendor(newValue);
  };

  // Calculate the range of pages to display
  let startPage, endPage;
  const MAX_DISPLAY_PAGES = 3;
  if (npage <= MAX_DISPLAY_PAGES) {
    startPage = 1;
    endPage = npage;
  } else {
    if (currentpage <= Math.floor(MAX_DISPLAY_PAGES / 2)) {
      startPage = 1;
      endPage = MAX_DISPLAY_PAGES;
    } else if (currentpage + Math.floor(MAX_DISPLAY_PAGES / 2) >= npage) {
      startPage = npage - MAX_DISPLAY_PAGES + 1;
      endPage = npage;
    } else {
      startPage = currentpage - Math.floor(MAX_DISPLAY_PAGES / 2);
      endPage = currentpage + Math.floor(MAX_DISPLAY_PAGES / 2);
    }
  }
  // PAGINATION END
  return (
    <div className="pt-4">
      <div className="container mt-5">
        <div className="row  profilefirstcom ">
          <div className="">
            <div className="p-5 ">
              <div className="row">
                <div className="col-lg-5 col-sm-12">
                  <Autocomplete
                    id="standard-basic"
                    options={Object.keys(allVendor).map((key) => ({
                      id: allVendor[key].pr_vendor_id,
                      name: allVendor[key].pr_vendor_name,
                    }))}
                    getOptionLabel={(option) => `${option.name} - ${option.id}`}
                    onChange={handleChange} // Handle change event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Vendor"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <FormControl fullWidth variant="standard">
                    <TextField
                      id=""
                      name=""
                      variant="standard"
                      label=" No of keys"
                      value={keyCount || ""}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^\d]+/g, ""); // Remove all non-numeric characters except "e"
                        setKeyCount(value);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-2 ">
                  <Button
                    variant="contained"
                    className="next-btn"
                    onClick={() => GenerateKey()}
                  >
                    Generate
                  </Button>
                </div>
              </div>
            </div>
            <div className="table-wrapper ">
              <div className="table-title">
                <div className=" ">
                  <div className="col-sm-6">
                    <h2>Activation Key Details</h2>
                  </div>
                  <div className="col-sm-6">
                    {/* <Link to="#addEmployeeModal" className="btn btn-success" data-toggle="modal"><i className="material-icons">&#xE147;</i> <span>Add New Employee</span></Link> */}
                    {/* <Link to="#deleteEmployeeModal" className="btn btn-danger" data-toggle="modal"><i className="material-icons">&#xE15C;</i> <span>Delete</span></Link> */}
                  </div>
                </div>
              </div>

              {loadingTable ? (
                <>
                  <TableLoader />
                </>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover text-center ">
                      <thead>
                        <tr>
                          <th>SrNo</th>
                          <th>Activation Key</th>
                          <th>Vendor Id</th>
                          <th>Used By</th>
                          {/* <th>Package</th> */}
                          <th>Delete</th>
                          <th>Issue Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records && records.length > 0 ? (
                          records &&
                          records.map((item, i) => {
                            return (
                              <tr key={i} className="p-5 " id="kyc">
                                <td>
                                  {i + 1 + (currentpage - 1) * recordperpage}
                                </td>

                                <td
                                  role="button"
                                  onClick={() =>
                                    copyToClipboard(item?.pr_act_keys)
                                  }
                                >
                                  <span className="">{item?.pr_act_keys}</span>
                                </td>
                                <td>{item?.pr_generatedby}</td>
                                <td>{item?.pr_usedby?.length>0?item?.pr_usedby:"N/A"}</td>
                                {/* <td>{item?.pr_packageamt}</td> */}
                                {!item?.pr_usedby?.length > 0 ? (
                                  <td className="p-0">
                                    <button
                                      type="button"
                                      className=" py-2 px-3 border-0 rounded badge badge-danger"
                                      onClick={() =>
                                        confirmButton(item?.pr_act_keys)
                                      }
                                    >
                                      <span>
                                        <i className="text-danger fa fa-trash fs-6"></i>
                                      </span>
                                    </button>
                                  </td>
                                ) : (
                                  <td>

                                    <span>
                                      <i className="text-danger fa fa-ban fs-6"></i>
                                    </span>
                                  </td>
                                )}
                                <td>{item?.pr_generateddate}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan="17">NO DATA FOUND</td>
                          </tr>
                        )}
                      </tbody>
                    </table>{" "}
                  </div>
                  {/* Pagination start */}
                  {records && records.length > 0 && (
                    <div className="clearfix">
                      <div className="hint-text">
                        Showing{" "}
                        <b>
                          {(currentpage - 1) * recordperpage + 1} to{" "}
                          {Math.min(
                            currentpage * recordperpage,
                            keyDetails?.length
                          )}
                        </b>{" "}
                        out of <b>{keyDetails?.length}</b> entries
                      </div>
                      <div className="row mt-2 me-sm-2">
                        <div className="col-sm-6  ms-auto  px-0">
                          <ul className="pagination my-2 d-flex justify-content-end">
                            <li
                              className={`page-item ${currentpage === 1 ? "disabled" : ""
                                }`}
                            >
                              <span
                                className="page-link"
                                onClick={() => ChangeCpage(1)}
                              >
                                First
                              </span>
                            </li>
                            <li
                              className={`page-item ${currentpage === 1 ? "disabled" : ""
                                }`}
                            >
                              <span className="page-link" onClick={prepage}>
                                Prev
                              </span>
                            </li>
                            {Number.map((item) => {
                              if (item >= startPage && item <= endPage) {
                                return (
                                  <li
                                    className={`page-item ${currentpage === item ? "active" : ""
                                      }`}
                                    key={item}
                                  >
                                    <span
                                      className="page-link"
                                      onClick={() => ChangeCpage(item)}
                                    >
                                      {item}
                                    </span>
                                  </li>
                                );
                              }
                              return null;
                            })}
                            <li
                              className={`page-item ${currentpage === npage ? "disabled" : ""
                                }`}
                            >
                              <span className="page-link" onClick={Nextpage}>
                                Next
                              </span>
                            </li>
                            <li
                              className={`page-item ${currentpage === npage ? "disabled" : ""
                                }`}
                            >
                              <span
                                className="page-link"
                                onClick={() => ChangeCpage(npage)}
                              >
                                Last
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Pagination end */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
