import commonRequest from "../../../CallApis/ApiCall"







export const send_partner_connection = async (data) => {
    let endPoint = "userdetails"
    return await commonRequest('POST', endPoint, data)


}



//  get seach details  || METHOD=GET

export const GET_USER_VALIDITY  = async (data) => {
    let endPoint = "userdetails"
    return await commonRequest('POST', endPoint, data)
}


//  get profile match  || METHOD=GET



export const get_profile_match_API = async (data) => {
    let endPoint = "userdetails"
    return await commonRequest('POST', endPoint, data)


}