import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
// import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.css';
import './styles/index'
import AOS from 'aos';
import 'aos/dist/aos.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import store, { persistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
AOS.init();




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   
      
           

    <>
    <Provider store={store}>
      <PersistGate persistor={persistor} >
      <Helmet>
            <meta name="google-site-verification" content="ensNMAt2ZasyqmKLjRYvR7lD3pvW1t6_WLCacVBkzXc" />
            {/* Other meta tags */}
        </Helmet>
           <App />

      </PersistGate>
      {/* <Login/> */}
      {/* <Main /> */}
    </Provider>
    {/* <Routing/> */}
  </>
 
);

reportWebVitals();
