import React, { useEffect, useState } from "react";
import { maleicon,femaleicon } from "../../images";
import { FaSpinner, FaPaperPlane } from "react-icons/fa";
import { get_profile_match_API, send_partner_connection} from "../../Services/APIs/USER_APIs/Search";
import {
  FaClock,
  FaShieldAlt,
  FaUserFriends,
  FaStar,
  FaCheckCircle,
} from "react-icons/fa";
import "../../styles/search2.css";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Search2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const { userId } = useSelector((state) => state.data);
  const { userDetails } = useSelector((state) => state.data);
  const [userMatch, setUserMatch] = useState([]);

  const getUserProfileMatch = async () => {
    try {
      const params = new URLSearchParams();
      params.append("dbparam", "getmypartners");
      params.append("pr_userid", userId);

      let response = await get_profile_match_API(params);
      console.log("🚀 ~ getUserProfileMatch ~ response:", response);

      let data = response?.data;
      console.log("🚀 ~ getUserProfileMatch ~ data:", data);

      if (data?.statusCode == 200) {
        let matches = data?.data;
        let partnerDetails = matches?.partnerDetails;
        let connectionRequestdails = matches?.connectionRequest;

        let mergedDetails = [];

        for (let i = 0; i < partnerDetails.length; i++) {
          let partner = partnerDetails[i];
          let foundMatch = false; // Flag to check if a match is found for this partner

          for (let j = 0; j < connectionRequestdails.length; j++) {
            let request = connectionRequestdails[j];

            if (partner.pr_userid == request.pr_receiver_id) {
              mergedDetails.push({
                ...partner,
                ...request,
                showConnectButton: false,
              });
              foundMatch = true;
              break; // Break the loop once a match is found
            }
          }

          if (!foundMatch) {
            // If no match is found for this partner, push with showConnectButton true
            mergedDetails.push({
              ...partner,
              showConnectButton: true,
            });
          }
        }

        console.log("🚀 ~ getUserProfileMatch ~ matches:", matches);
        setUserMatch(mergedDetails);
        // setUserMatch(matches?.partnerDetails);
      } else {
        setUserMatch([]);
      }
    } catch (error) {
      setUserMatch([]);
    }
  };
  useEffect(() => {
    getUserProfileMatch();
  }, [userId]);

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  const connect = async (partnerId) => {
    setIsLoading(true);
    setTimeout(() => {
      // Your connect logic here
      setIsConnected(true);
      setIsLoading(false);
    }, 1500);
    try {
      const params = new URLSearchParams();
      params.append("dbparam", "sendconnectrequest");
      params.append("pr_userid", userId);
      params.append("pr_partner_id", partnerId);

      let response = await send_partner_connection(params);

      let data = response?.data;
      console.log("🚀 ~ getUserProfileDetails ~ data:", data);

      if (data?.statusCode == 200) {
        toast.success("Connection Successfully Send");
        getUserProfileMatch();
      } else {
        toast.error("Failed Connection Send");
      }
    } catch (error) { }
    console.log("🚀 ~ getUserProfileDetails ~ userId:", userId);
  };

  return (
    <>
      <div
        className="container one"
        style={{ marginTop: "10%", marginBottom: "3%" }}
      >
        <div className="row d-flex justify-content-center bg-light ">
          <div className="col-lg-8 ">
            {userMatch &&
              userMatch?.map((data, index) => {
                const age = calculateAge(data.pr_dob);
                return (
                  <div className="row my-5 shadow1" key={index}>
                    <div className="col-lg-3 p-0 m-0 border">
                      <img
                        src={
                          data?.pr_profile_picture?.length > 0
                            ? `${process.env.REACT_APP_API_URL_FOR_IMAGE}/${data?.pr_profile_picture}`
                            : data?.pr_gender == "male"
                              ? maleicon
                              : femaleicon
                        }
                        alt="Img-1"
                        className="img-fluid img-search "
                      />
                    </div>
                    <div className="col-lg-6  ">
                      <div className="p my-2">
                        {data.pr_name} <FaShieldAlt className="icon-shield" />
                      </div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <span className="m-a">
                            <FaClock className="icon-clock" /> Online 5h ago
                          </span>
                          <span className="m-a">
                            <FaUserFriends className="icon-friends" /> You & her
                          </span>
                          <span className="m-a">
                            <FaStar className="icon-star" /> Astro
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-lg-6">
                          {age}yrs, {data.pr_height}
                          <br />
                          {data.pr_category} <br />
                          Hindi
                        </div>
                        <div className="col-lg-6">
                          {data.pr_marital_status} <br />
                          {data.pr_city}, {data.pr_state} <br />
                          {data.pr_working_with} /{data.pr_working_as}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-auto p fw-lighter">
                          Hi, I've put up this profile on behalf of my daughter.
                          She has completed her BE/B.Tech. She beli...
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 border-start d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <p>Like this profile?</p>
                        {data?.showConnectButton == true ? (
                          <>
                            {" "}
                            <button
                              className="border-0 bg-light"
                              onClick={() => connect(data?.pr_userid)}
                            >
                              {isLoading ? (
                                <FaSpinner className="icon-connect my-2 spin" />
                              ) : (
                                <FaPaperPlane className="icon-connect icon-connect2 my-2" />
                              )}
                            </button>
                            <p>Connect Now</p>
                          </>
                        ) : (
                          <>
                            <button className="border-0 bg-light">
                              <FaCheckCircle className="icon-connect  my-2" />
                            </button>
                            <p>Request Send</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Search2;
