import Layout from "./Layout";
function App() {
  return (
    <div className="App">
<Layout/>
    </div>
  );
}

export default App;
