import React from 'react'
import GenerateKey from '../../Component/Admin/Generatekey/GenerateKey'

export default function GenerateKeyPage() {
  return (
 <>
 <div className="container-fluid ">
  <div className="row backround-color-theam">
   <GenerateKey/>
   </div>
 </div>


 </>
  )
}
