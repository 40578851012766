import * as types from './actionType'

export const LoginUser = (userId, login, userDetails,isUserUpdated,userType) => (
    {
        type: types.LOGINUSER,
        payload: {
            userId: userId,
            isLoggedIn: login,
            userDetails: userDetails,
            isUserUpdated:isUserUpdated,
            userType:userType
        }
    })

export const LogoutUser = () => (
    {
        type: types.LOGOUT
    })


    
export const updateUserDetails = (data) => (
    {
        type: types.UPDATEUSERDETAILS,
        payload:data

    })

 
    export const UserisUpdated =(data) => {
        console.log("🚀 ~ UserisUpdated ~ data:", data)
        
        return (
        {
            type:types.USERISUPDATED,
            payload: data
        }
    )}
    
// export const showOfferRedux = (data) => (
//     {
//         type: types.SHOWOFFER,
//         payload:data

//     })

// export const  LOGIN =(inputs)=> {
//     dispatch(LoginUser(inputs))
// }