import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { logo } from '../../images/index';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // map link
    const latitude = 20.736376;
    const longitude = 78.58382;
    const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;

    const logoStyle = {
        height: "60px",
        width: "auto"
    }

    const footersection = {
        background: ""
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <footer className="container-fluid p-0 pt-2 m-0 footersection bg-footer heading-cursive">
            <div className="container" data-cursor-color="var(--primary-bg-color)"  data-cursor-text=" "
            data-cursor-size="5px">
                <div className="row d-flex justify-content-around p-2">
                    <section className="col-lg-3 mb-2">
                        <div className="row mb-2">
                            <div className="col-lg-12 p-1 logopoint">
                                <img src={logo} style={logoStyle} alt="footerLogo" />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-lg-12 p-1 logopoint">
                                <p className='extra '>We’re always in search for talented and motivated people. Don’t be shy introduce yourself!</p>
                            </div>
                        </div>
                        <div className="row mb-2 py-2">
                            <div className="col-lg-12 p-1 socialThings text-white">
                                <div className="row p-0 m-0 d-flex justify-content-start gap-3">
                                    <div className="col-lg-2 col-2 text-center fs-3 d-flex align-items-center justify-content-center p-0 m-0">
                                        <a className='nav-link mb-0' href="#"><i className="fab fa-facebook SocialiconSet p-0"></i></a>
                                    </div>
                                    <div className="col-lg-2 col-2 text-center fs-3 d-flex align-items-center justify-content-center p-0 m-0">
                                        <a className='nav-link mb-0' href="#"><i className="fa-brands fa-instagram SocialiconSet p-0"></i></a>
                                    </div>
                                    <div className="col-lg-2 col-2 text-center fs-3 d-flex align-items-center justify-content-center p-0 m-0">
                                        <a className='nav-link mb-0' href="#"><i className="fa-brands fa-twitter SocialiconSet p-0"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="col-lg-2 mb-2 text-start">
                        <ul className='nav-items p-0 text-start mt-4 '>
                            <li className='nav-link text-start p-0 px-1 mb-3 text-white'>
                                <h6 className='fw-bolder footerMainHead'>Useful Links</h6>
                            </li>
                            <li className='nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/about#faq" className='nav-link'>FAQ</Link>
                            </li>
                            <li className='nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/about" className='nav-link'>About Us</Link>
                            </li>
                            <li className='nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/privacy-policy" className='nav-link'>Privacy Policy</Link>
                            </li>
                        </ul>
                    </section>

                    <section className="col-lg-2 mb-2">
                        <ul className='nav-items p-0 text-start mt-4 '>
                            <li className='nav-link  text-start p-0 px-1 mb-3 text-dark'>
                                <h6 className='fw-bolder footerMainHead ternarytextcolor'>Our Company</h6>
                            </li>
                            <li className='nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/" className='nav-link'>Home</Link>
                            </li>
                            <li className='nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/about" className='nav-link'>About Us</Link>
                            </li>
                            
                            <li className='nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/portfolio" className='nav-link'>Portfolio</Link>
                            </li>
                            <li className='  nav-link text-start p-0 px-1 mb-3 text-dark'>
                                <Link to="/contact" className='nav-link'>Contact Us</Link>
                            </li>
                        </ul>
                    </section>

                    <section className="col-lg-3 mb-2">
                        <ul className='nav-items p-0 text-start mt-4 '>
                            <li className='nav-link  text-start p-0 px-1 mb-3 text-dark'>
                                <h6 className='fw-bolder footerMainHead'>Get Contact</h6>
                            </li>
                            <li className='nav-link  text-start p-0 px-1 mb-3 text-dark'>
                                Phone:<br />
                                <a className='nav-link mb-0' href="tel:+919527569556">+91-9527569556</a>
                            </li>
                            <li className='nav-link  text-start p-0 px-1 mb-3 text-dark'>
                                E-mail:<br />
                                <a className='nav-link mb-0' href="mailto:info@iparinay.com">info@iparinay.com</a>
                            </li>
                            <li className='nav-link  text-start p-0 px-1 mb-3 text-dark'>
                                Our Location:<br />
                                <a className='nav-link mb-0' href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                                Nagri Bank Colony, Near Ramabai School, Wardha - 442001
                                </a>
                            </li>
                        </ul>
                    </section>

                </div>
            </div>

            <div className="row d-flex justify-content-center m-0 text-center align-items-center p-0">
                <div className="col-lg-12 p-2 m-0 copyrightsection">
                    <p className='text-center m-0 p-0 text-dark'>
                        Copyright  © {new Date().getFullYear()} Iparinay .  All Rights Reserved
                        <a className='nav-link mb-0 text-dark' href="https://www.appzminetech.com" target='_blank'>Design And Developed by{' '} Appzmine Tech Pvt Ltd</a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
