import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TableLoader from '../../../Loader/TableLoader';
import { useSelector } from 'react-redux';
import { GET_ALL_USER_DETAILS_API } from '../../../Services/APIs/ADMIN_APIs/AllUserDetails';

export default function AdminUserdetails() {

  const { userId } = useSelector((state) => state.data);

  const [loadingTable, setLoadingTable] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);



  const getAllUserDeatils = async () => {
    try {
      const params = new URLSearchParams();
      params.append("dbparam", "getalluserdetails");
      params.append("pr_userid", userId);

      let response = await GET_ALL_USER_DETAILS_API(params);

      let data = response?.data;
      console.log("🚀 ~ getUse  rProfileDetails ~ data:", data);

      if (data?.statusCode == 200) {
        setFilteredData(data?.data)
        // setFilteredData([])
        setUserDetails(data?.data)

      } else {
      }
    } catch (error) {


    } finally {
      setLoadingTable(false)
    }
  };
  useEffect(() => {
    getAllUserDeatils();
  }, [userId]);







  // PAGINATION

  const [currentpage, setCurrentpage] = useState(1);
  const recordperpage = 10;
  const lastIndex = currentpage * recordperpage;
  const firstIndex = lastIndex - recordperpage;
  let records = filteredData.sort(
    (a, b) => new Date(a.pr_date) - new Date(b.pr_date)
  ); //// ARRAY OBJECT
  records = records.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordperpage);
  const Number = [...Array(npage + 1).keys()].slice(1);

  function prepage() {
    if (currentpage !== 1) {
      setCurrentpage(currentpage - 1);
    }
  }
  function Nextpage() {
    if (currentpage !== npage) {
      setCurrentpage(currentpage + 1);
    }
  }
  function ChangeCpage(id) {
    setCurrentpage(id);
  }

  // Calculate the range of pages to display
  let startPage, endPage;
  const MAX_DISPLAY_PAGES = 3;
  if (npage <= MAX_DISPLAY_PAGES) {
    startPage = 1;
    endPage = npage;
  } else {
    if (currentpage <= Math.floor(MAX_DISPLAY_PAGES / 2)) {
      startPage = 1;
      endPage = MAX_DISPLAY_PAGES;
    } else if (currentpage + Math.floor(MAX_DISPLAY_PAGES / 2) >= npage) {
      startPage = npage - MAX_DISPLAY_PAGES + 1;
      endPage = npage;
    } else {
      startPage = currentpage - Math.floor(MAX_DISPLAY_PAGES / 2);
      endPage = currentpage + Math.floor(MAX_DISPLAY_PAGES / 2);
    }
  }
  // PAGINATION END
  return (

    <div className="pt-4">
      <div className="container mt-5">
        <div className="row  profilefirstcom ">
          <div className="">
            <div className="table-wrapper ">
              <div className="table-title">
                <div className=" ">
                  <div className="col-sm-6">
                    <h2>User <b>Details</b></h2>
                  </div>
                  <div className="col-sm-6">
                    {/* <Link to="#addEmployeeModal" className="btn btn-success" data-toggle="modal"><i className="material-icons">&#xE147;</i> <span>Add New Employee</span></Link> */}
                    {/* <Link to="#deleteEmployeeModal" className="btn btn-danger" data-toggle="modal"><i className="material-icons">&#xE15C;</i> <span>Delete</span></Link> */}
                  </div>
                </div>
              </div>

              {loadingTable ? (
                <>
                  <TableLoader />
                </>
              ) : (
                <>

                  <div className="table-responsive">



                    <table className="table table-striped table-hover text-center ">
                      <thead>
                        <tr>
                          <th>
                            Sr No
                          </th>
                          <th>Name</th>
                          <th>User Id</th>
                          <th>Password</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Gender</th>
                          <th>State</th>
                          <th>City</th>
                          <th>Category</th>
                          <th>Marital Status</th>
                          <th>Validity Expire Date</th>
                          <th>Date of Joining</th>
                          <th>Action</th>


                        </tr>
                      </thead>
                      <tbody>
                        {records && records.length > 0 ? (
                          records &&
                          records.map((item, i) => {
                            return (
                              <tr key={i} className="p-5 " id="kyc">
                                <td>
                                  {i +
                                    1 +
                                    (currentpage - 1) * recordperpage}
                                </td>

                                <td>{item?.pr_name}</td>
                                <td>{item?.pr_userid}</td>
                                <td>{item?.pr_password}</td>
                                <td>{item?.pr_email}</td>
                                <td>{item?.pr_mobile}</td>
                                <td>{item?.pr_gender}</td>
                                <td>{item?.pr_state}</td>
                                <td>{item?.pr_city}</td>
                                <td>{item?.pr_category}</td>
                                <td>{item?.pr_marital_status}</td>
                                <td>{item?.pr_validity_expire_date}</td>
                                <td>{item?.pr_date}</td>
                                <td><button className='bg-primary text-white border-0 rounded-pill px-3'>Edit</button></td>

                                {/* <td>{item.vr_userid}</td>
                                                  <td>{item.vr_driver_id}</td>


                                                  <td>{item.vr_date}</td> */}

                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan="17">NO DATA FOUND</td>
                          </tr>
                        )}
                      </tbody>


                    </table> </div>
                  {/* Pagination start */}
                  {records && records.length > 0 && (
                    <div className="clearfix">
                      <div className="hint-text">Showing <b>{(currentpage - 1) * recordperpage + 1} to {Math.min(currentpage * recordperpage, userDetails?.length)}</b> out of <b>{userDetails?.length}</b> entries</div>
                      <div className="row mt-2 me-sm-2">
                        <div className="col-sm-6  ms-auto  px-0">
                          <ul className="pagination my-2 d-flex justify-content-end">
                            <li
                              className={`page-item ${currentpage === 1 ? "disabled" : ""
                                }`}
                            >
                              <span
                                className="page-link"
                                onClick={() => ChangeCpage(1)}
                              >
                                First
                              </span>
                            </li>
                            <li
                              className={`page-item ${currentpage === 1 ? "disabled" : ""
                                }`}
                            >
                              <span className="page-link" onClick={prepage}>
                                Prev
                              </span>
                            </li>
                            {Number.map((item) => {
                              if (item >= startPage && item <= endPage) {
                                return (
                                  <li
                                    className={`page-item ${currentpage === item ? "active" : ""
                                      }`}
                                    key={item}
                                  >
                                    <span
                                      className="page-link"
                                      onClick={() => ChangeCpage(item)}
                                    >
                                      {item}
                                    </span>
                                  </li>
                                );
                              }
                              return null;
                            })}
                            <li
                              className={`page-item ${currentpage === npage ? "disabled" : ""
                                }`}
                            >
                              <span
                                className="page-link"
                                onClick={Nextpage}
                              >
                                Next
                              </span>
                            </li>
                            <li
                              className={`page-item ${currentpage === npage ? "disabled" : ""
                                }`}
                            >
                              <span
                                className="page-link"
                                onClick={() => ChangeCpage(npage)}
                              >
                                Last
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Pagination end */}



                </>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );


}
