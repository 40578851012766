import commonRequest from "../../../CallApis/ApiCall"



///GET ALL USER || METHOD=POST
export const GET_ALL_USER_DETAILS_API = async (data) => {
    let endPoint = "admin_panel"
    return await commonRequest('POST', endPoint, data)

}


