import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "./pages/NotFound.js";
// const Navbar = lazy(() => import("./Navbar.js"));
import MainNavbar from "./MainNavbar.js";
import { useSelector } from "react-redux";
import Detail from "./pages/Details.js";
import Search2 from "./pages/User/Search2.js";
import LoadingSpinner from "./Loader/LoadingSpinner.js";
import AdminUserdetails from "./Component/Admin/AllUserdetails/AdminUserdetails.js";
import GenerateKeyPage from "./pages/Admin/GenerateKeyPage.js";
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const UserHome = lazy(() => import("./Component/UserDash/UserHome.js"))
const MyProfile = lazy(() => import("./pages/User/UserProfile.js"))
const MyPhoto = lazy(() => import("./Component/UserDash/MyPhoto.js"))
const UserDash = lazy(() => import("./pages/User/UserDash.js"))
const Loginform = lazy(() => import("./pages/auth/Login.js"))
const SignUp = lazy(() => import("./pages/auth/SignUp.js"))
const Photos = lazy(() => import("./pages/User/Photos.js"))
const PartPref = lazy(() => import("./pages/User/Prefereance.js"))
const Search = lazy(() => import("./pages/User/Search.js"))
const Updatedetails = lazy(() => import("./pages/auth/UpdateUserDetails.js"))
// const EditUserCom = lazy(() => import("./Component/Admin/AllUserdetails/subcomponents/EditSingleUSer.js"))
// 
// admin 
const AdminDash = lazy(() => import ("./pages/Admin/AdminDashboardPage.js"))


const Layout = () => {


  const { isLoggedIn, isUserUpdated, userType } = useSelector(state => state.data);
  console.log("🚀 ~ Layout ~ userType:", userType)
  console.log("🚀 ~ Layout ~ isLoggedIn:", isLoggedIn)



  const renderRoutebasedUser = () => {


    if (isLoggedIn && isLoggedIn == true) {


      if (userType == "USER") {
        if (isUserUpdated == 1) {
          return (
            <>
              <Route path="dashboard" element={<UserDash />} />
              <Route path="profile" element={<MyProfile />} />
              <Route path="photos" element={<Photos />} />
              <Route path="partnerpref" element={<PartPref />} />
              <Route path="search2" element={<Search2 />} />
              <Route path="login" element={<Navigate to="/" />} />
              <Route path="signup" element={<Navigate to="/" />} />
            </>
          )
        } else {
          return (

            <>
              <Route path="updatedetails" element={< Updatedetails />} />
              <Route path="*" element={<Navigate to="/updatedetails" />} />

            </>
          )
        }
      } else if (userType == "VENDOR") {
        return (

          <>
            <Route path="login" element={<Navigate to="/" />} />
            <Route path="signup" element={<Navigate to="/" />} />
          </>
        )

      } else if (userType == "ADMIN") {
        return (

          <>
           <Route path="dashboard" element={<AdminDash />} />
           <Route path="alluser" element={<AdminUserdetails />} />
           {/* <Route path="edituser" element={<EditUserCom />} /> */}
           
           <Route path="generatekey" element={<GenerateKeyPage />} />
            <Route path="login" element={<Navigate to="/" />} />
            <Route path="signup" element={<Navigate to="/" />} />
          </>
        )

      }




    }



  }



  return (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<LoadingSpinner/>} >
        
        <Routes>
          <Route path="/" element={<MainNavbar />}>
            <Route index element={<Home />} />


            {renderRoutebasedUser()}

            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="login" element={<Loginform />} />
            {/* <Route path="signup" element={<StepperForm/>} /> */}
            <Route path="signup" element={< SignUp />} />
            <Route path="detail" element={< Detail />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Layout;