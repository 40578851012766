import rootReducer from "../reducer/rootReducer";
import { legacy_createStore as createStore, applyMiddleware} from 'redux'
import {thunk} from 'redux-thunk';
import storageSession from 'redux-persist/es/storage';
import {persistReducer , persistStore} from 'redux-persist';

const middleware = [thunk];

const persistConfig = {
    key : 'persist-root',
    storage :storageSession
  }
  const persistReducers = persistReducer(persistConfig , rootReducer );
  const store = createStore(persistReducers, applyMiddleware(...middleware));
 export const persistor = persistStore(store);


export default store;



