import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { logo } from "../images";

const Detail = () => {
  const location = useLocation();
  let data = location && location?.state;
  const [userDetail, setUserDetail] = useState({});
  useEffect(() => {
    setUserDetail(data);
  }, [location?.state]);
  console.log(userDetail);

  const logoStyle = {
    height: "100px",
    width: "auto",
  };

  const redirectTodetail = ()=>{
    window.location.href = "/Login";
  }
  
  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center mt-5  ">
          <div className="col-auto mt-5  welcome-card">
            <div className="row">
                <div className="col">
                    <div className="welcome-card-circle">

              <img src={logo} style={logoStyle} alt="logo" />
                    </div>

                </div>
            </div>
            <div class="row">
              <div className="col-5 px-0">
              </div>
              <div className="col-7"></div>

            </div>
            <h3 className="px-3">Welcome to parinay</h3>
            <div className="py-2 px-3">Name : {userDetail?.name}</div>
            <div className="py-2 px-3">UserId : {userDetail?.userId}</div>
            <div className="py-2 px-3">Password : {userDetail?.password}</div>
            <div className="py-2 px-3 text-light">Note :- Please note your user ID and password.</div>
            <button type="button" class="btn  my-3 mx-3" onClick={redirectTodetail}>Get Started</button>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;