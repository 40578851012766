import * as types from '../actions/actionType'

const initialState = {
    isLoggedIn: false,
    userId: '',
    userDetails: {},
    isUserUpdated: null,
    userType: null
};

const userReducer = (state = initialState, action) => {
    console.log("🚀 ~ userReducer ~ action:", action)

    switch (action.type) {

        case types.LOGINUSER:
            return {
                ...state,
                userId: action.payload.userId,
                isLoggedIn: action.payload.isLoggedIn,
                userDetails: action.payload.userDetails,
                isUserUpdated: action.payload.isUserUpdated,
                userType: action.payload.userType
            }
        case types.LOGOUT:
            return {
                isLoggedIn: false,
                userId: '',
                userDetails: {},
                isUserUpdated: null,
                userType: null
            };
        // case types.SHOWSIDEBAR:
        //     return {
        //         ...state,
        //         sideBar: action.payload

        //     };
        case types.UPDATEUSERDETAILS:
            return {
                ...state,
                userDetails: action.payload

            };

        case types.USERISUPDATED:
            return {
                ...state,
                isUserUpdated: action.payload

            }
        // case types.SHOWOFFER:
        //     return {
        //         ...state,
        //         showOffer: action.payload

        //     };
        default:
            return state;

    }

}

export default userReducer;